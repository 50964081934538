import React from 'react';
import './App.css';
import SpotifyEmbed from './SpotifyEmbed';

function App() {

  return (
    <div className="outer-container">
      <div className="container">
        <h1>hi there, i'm shreya rathore</h1>
        <p>here's what im currently listening to</p>
        <SpotifyEmbed />
      </div>
    </div>
  );
}

export default App;
