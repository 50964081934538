import React from 'react';

function SpotifyEmbed() {
  return (
    <div className="spotify-embed">
      <iframe
        src="https://open.spotify.com/embed/track/3LbZIhU0smEU5SUnxod4j4?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowtransparency="false"
        allowfullscreen=""
        title="Spotify Embed"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default SpotifyEmbed;
